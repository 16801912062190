<template>
    <div>
        <el-form :model='formListQuery' :rules='rules' ref='variantFrom'>
            <el-form-item label='名称' prop='title' :label-width='formLabelWidth'>
                <el-input v-model='formListQuery.title' class='input-width' placeholder='名称'></el-input>
            </el-form-item>
            <el-form-item label='品牌' prop='brandCode' :label-width='formLabelWidth'>
                <!-- <dataSelect v-model='formListQuery.brandCode' :codeType="'brand'" clearable></dataSelect> -->
                <el-select class="filter-item" v-model="formListQuery.brandCode" placeholder="品牌" style="width: 180px" clearable >
                      <el-option v-if="myTenantid == '1001001000' || myTenantid == '1001001006'" :disabled="aigoBrandStatus"  value="AigoStar">AigoStar</el-option>
                      <el-option v-if="myTenantid == '1001001000' || myTenantid == '1001001006'" :disabled="noblezaBrandStatus"  value="Nobleza">Nobleza</el-option>
                      <el-option v-if="myTenantid == '1001002001'"  value="Unico">Unico</el-option>
                    </el-select>
            </el-form-item>
            <el-form-item label='位置' prop='bannerPosition' :label-width='formLabelWidth'>
                <dataSelect
                    @change='changeBannerPosition'
                    v-model='formListQuery.bannerPosition'
                    :codeType="'banner_position'"
                    clearable
                ></dataSelect>
            </el-form-item>
            <el-form-item label='类型' prop='bannerType' :label-width='formLabelWidth'>
                <el-radio-group v-model='formListQuery.bannerType' @change='changeCannerType'>
                    <el-radio v-for='(item, index) in dictionary.type' :key='item.id' :label='item.value'>
                        {{ item.name }}
                    </el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label='国家' prop='bannerCountriesList' :label-width='formLabelWidth'>
                <el-checkbox :indeterminate='isIndeterminate' v-model='checkAll' @change='handleCheckAllChange'>全选
                </el-checkbox>
                <el-checkbox-group v-model='formListQuery.bannerCountriesList' @change='handleCheckedCitiesChange'>
                    <el-checkbox v-for='(item, index) in dictionary.country' :key='item.code' :label='item.code'>
                        {{ item.name }}
                    </el-checkbox>
                </el-checkbox-group>
            </el-form-item>
            <el-form-item label='展示时间' prop='time' :label-width='formLabelWidth'>
                <el-date-picker
                    v-model='formListQuery.time'
                    clearable
                    @input='clearValue'
                    :key='itemKey'
                    type='datetimerange'
                    range-separator='至'
                    start-placeholder='开始日期'
                    end-placeholder='结束日期'
                >
                </el-date-picker>
            </el-form-item>
            <el-form-item label='跳转类型' prop='bannerJumpType' :label-width='formLabelWidth'>
                <el-radio-group v-model='formListQuery.bannerJumpType' @change='changeBannerJump'>
                    <el-radio v-for='(item, index) in dictionary.jump' :key='item.id' :label='item.value'>
                        {{ item.name }}
                    </el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label='是否启用' prop='status' :label-width='formLabelWidth'>
                <el-switch v-model='formListQuery.status' active-color='#13ce66' inactive-color='#ff4949'></el-switch>
            </el-form-item>
            <el-form-item label='排序' prop='sort' :label-width='formLabelWidth'>
                <el-input
                    v-model='formListQuery.sort'
                    oninput="value=value.replace(/[^0-9]/g,'')"
                    @blur='salaryChange'
                    class='input-width'
                    placeholder='默认为0,数字越大,排序越靠前'
                ></el-input>
            </el-form-item>
            <el-form-item :label-width='formLabelWidth' v-show='false'>
                <dataSelect v-model='formListQuery.langCode' :codeType="'lang'" clearable></dataSelect>
            </el-form-item>
        </el-form>
        <el-tabs v-model='activeName' @tab-click='handleClick'>
            <el-tab-pane v-for='(item, index) in formListQuery.bannerFiles' :key='item.langCode' :label='item.name'
                         :name='item.langCode'>
                <div>
                    <div style='display: flex; justify-content: flex-start'
                         v-if="formListQuery.bannerType == 'Picture'">
                        <div class='uploadTitle'>图片</div>
                        <image-upload
                            :formListQuery='formListQuery'
                            :index='index'
                            :langCode='langCode'
                            @handleFormData='handleFormData'
                        ></image-upload>

                        <!-- <el-upload class='avatar-uploader' :action='ossInfo.ossSignature.host' :show-file-list='false'
                            :auto-upload='false' list-type='picture-card' ref='uploadImg' :on-change='handleChange'
                            :on-success='upLoadSuccess'
                            :data='fileDataObj' accept='.jpg, .jpeg, .png, .gif'>
                            <el-image v-if='item.imgUrl' class='avatar' :src='item.imgUrl' fit='contain'></el-image>
                            <i v-else class='el-icon-plus avatar-uploader-icon'></i>
                        </el-upload>	 -->
                        <el-button type='text' @click='syncLang(item.imgUrl)'>同步到其他语言</el-button>
                    </div>
                    <div
                        v-if="formListQuery.bannerPosition != 'HomeRecommended' && formListQuery.bannerType == 'Video'"
                        style='display: flex; justify-content: flex-start'
                    >
                        <div class='uploadTitle'>视频</div>
                        <video-upload
                            :formListQuery='formListQuery'
                            :index='index'
                            :langCode='langCode'
                            @handleFormData='handleFormData'
                        ></video-upload>
                        <!-- <el-upload v-if="item.videoUrl==''" class='avatar-uploader' :action='ossInfo.ossSignature.host'
                            :show-file-list='false' :auto-upload='false' list-type='picture-card' ref='uploadVideo'
                            :on-progress='uploadVideoProcess' :on-change='handleChange2' :data='fileDataObj2' accept='.mp4'>
                            <i v-if='videoUploadPercent==0' class='el-icon-plus avatar-uploader-icon'></i>
                            <el-progress v-else-if='videoUploadPercent>0' type='circle' :percentage='(videoUploadPercent)'
                                style='margin-top:30px;width: 178px;height: 178px;'></el-progress>
                        </el-upload> -->
                        <!-- <video class='float-l' style='width: 178px;height: 178px;' v-else :src='item.videoUrl'
                            controls='controls'>
                            您的浏览器不支持视频播放
                        </video>
                        <div style='margin-left: 20px;display: flex;justify-content: flex-start;align-items: flex-end;'>
                            <span v-if="item.videoUrl==''">支持mp4格式，≤500MB</span>
                            <el-button v-else type='primary' @click='backVideo' plain>重新上传</el-button>
                        </div> -->
                        <el-button type='text' @click='syncLang(item.videoUrl)'>同步到其他语言</el-button>
                    </div>
                    <div
                        v-if="formListQuery.bannerPosition == 'ContactUs' && formListQuery.bannerType == 'Video'"
                        style='display: flex; justify-content: flex-start; margin-top: 20px;'
                    >
                        <div class='uploadForm' >上传封面</div>

                        <image-upload :formListQuery='formListQuery'
                            :index='index'
                            :langCode='langCode'
                            :divShow = false
                            :maxSize='3'
                            acceptType = '.jpg, .jpeg, .png'
                            @handleFormData='handleFormData'
                        ></image-upload>
                      
                        <div style='margin-left: 20px;'>
                            <div >推荐尺寸：1920*1080</div>
                            <div >文件类型：JPG，JPEG，PNG</div>
                            <div >文件大小：≤3MB</div>                        
                        </div>  
                    </div>

                    <div style='display: flex; justify-content: flex-start; margin-top: 20px'>
                        <div class='uploadForm'>标题</div>
                        <el-input v-model='item.title' class='input-width' placeholder='标题' maxlength='500'
                                  show-word-limit></el-input>
                    </div>
                    <div
                        v-show="formListQuery.bannerJumpType === 'Link'"
                        style='display: flex; justify-content: flex-start; margin-top: 20px'
                    >
                        <div class='uploadTitle'>跳转链接</div>
                        <el-input v-model='item.jumpUrl' class='input-width' placeholder='输入跳转链接'></el-input>
                    </div>
                    <div
                        v-show="formListQuery.bannerPosition === 'CompanyProducts'"
                        style='display: flex; justify-content: flex-start; margin-top: 20px'
                    >
                        <div class='uploadForm'>内容</div>
                        <el-input v-model='item.description' type="textarea" class='input-width'
                                  placeholder='输入内容' :maxlength="maxlengthTextarea" show-word-limit
                                  :autosize="{ minRows: 5, maxRows: 10}"></el-input>
                    </div>
                </div>
                <el-form :model='formListQuery' :rules='rules'>
                    <el-form-item style='width: 20%; display: none'>
                        <div class='dialog-footer'>
                            <el-button type='primary' @click="onSubmit1('variantFrom')">确 定</el-button>
                        </div>
                    </el-form-item>
                </el-form>
            </el-tab-pane>
        </el-tabs>

        <div class='dialog-footer'>
            <el-button style='width: 20%' @click='closeDialog'>取 消</el-button>
            <el-button style='width: 20%' type='primary' @click='onSubmit'>确 定</el-button>
        </div>
        <el-dialog
            :title="'同步到其他语言'"
            :visible.sync='dialogFormVisible'
            :append-to-body='true'
            :before-close='closeCopyDialog'
            :close-on-click-modal='false'
        >
            <div>
                <el-checkbox :indeterminate='copyIsIndeterminate' v-model='copyCheckAll'
                             @change='handleCopyCheckAllChange'
                >全选
                </el-checkbox>
                <el-checkbox-group v-model='copyLangs' @change='handleCopyCheckedCitiesChange'>
                    <el-checkbox v-for='(item, index) in copyLangList' :key='item.code' :label='item.code'>
                        {{ item.name }}
                    </el-checkbox>
                </el-checkbox-group>
            </div>
            <div class='dialog-footer'>
                <el-button style='width: 20%' @click='closeCopyDialog'>取 消</el-button>
                <el-button style='width: 20%' type='primary' @click='onCopySubmit'>确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import { formatDate } from '@/utils/date';
import X2JS from 'x2js'; //解析xml
import { enumeration } from '@/utils/common2';
import cache from '@/utils/cache';
import DataSelect from '@/components/common/DataSelect';
import ImageUpload from '@/components/common/ImageUpload';
import VideoUpload from '@/components/common/VideoUpload';
import moment from 'moment';
import Config from '@/utils/config';
import scene from '@/utils/scene';
export default {
    components: {
        DataSelect,
        ImageUpload,
        VideoUpload,
        moment
    },
    props: {
        formListQuery: {
            type: Object,
            default: () => {
                return {};
            }
        },
        dictionary: {
            type: Object,
            default: () => {
                return {};
            }
        },
        bannerId: {
            type: String,
            default: ''
        },
        isEdit: {
            type: Boolean,
            default: false
        }
    },
    filters: {
        formatDate(time) {
            if (time == null || time === '') {
                return 'N/A';
            }
            let date = new Date(time);
            return formatDate(date, 'yyyy-MM-dd');
        },
        enumeration(val) {
            return enumeration(val);
        }
    },
    data() {
        return {
            maxlengthTextarea: '1000',
            itemKey: null, // 关键参数key
            video: null,
            ossUrl: '',
            videoFlag: 'false',
            videoUploadPercent: 0,
            ossVideoUrl: '',
            formLabelWidth: '120px',
            languageFrom: {},
            imageUrl: '',
            videoUrl: '',
            activeIndex: 0,
            activeName: 'zh',
            countryCode: 'CN',
            langCode: 'zh',
            aigoBrandStatus:false,
            noblezaBrandStatus:false,
            dialogVisible: false,
            fileList: [],
            fileDataObj: {},
            fileDataObj2: {},
            limit: 1,
            //组件标签是否显示
            arg: {
                code: true,
                country: true,
                status: true
            },
            //查询项
            searchData: {
                searchStr: '',
                saleCountry: [],
                platformCode: [],
                enabled: null,
                arrayDate: [],
                page: 1,
                limit: 10
            },
            //选中数量
            rules: {
                title: [
                    {
                        required: true,
                        message: '请输入名称',
                        trigger: 'blur'
                    },
                    {
                        min: 1,
                        max: 50,
                        message: '长度在 1 到 50 个字符',
                        trigger: 'blur'
                    }
                ],
                brandCode: [
                    {
                        required: true,
                        message: '请选择品牌',
                        trigger: 'change'
                    }
                ],
                bannerPosition: [
                    {
                        required: true,
                        message: '请选择位置',
                        trigger: 'change'
                    }
                ],
                bannerType: [
                    {
                        required: true,
                        message: '请选择类型',
                        trigger: 'change'
                    }
                ],
                bannerCountriesList: [
                    {
                        required: true,
                        message: '请至少选择一个国家',
                        trigger: 'change'
                    }
                ],
                time: [
                    {
                        required: true,
                        message: '请选择日期',
                        trigger: 'change'
                    }
                ],
                bannerJumpType: [
                    {
                        required: true,
                        message: '请选择跳转类型',
                        trigger: 'change'
                    }
                ],
                status: [
                    {
                        required: true,
                        message: '请选择是否启用',
                        trigger: 'change'
                    }
                ],
                targetUrl: [
                    {
                        required: true,
                        message: '请输入名称',
                        trigger: 'blur'
                    },
                    {
                        min: 1,
                        max: 50,
                        message: '长度在 1 到 50 个字符',
                        trigger: 'blur'
                    }
                ]
            },
            checkAll: false,
            isIndeterminate: false,
            ossInfo: {
                ossHost: process.env.VUE_APP_OSS_Head,
                ossSignature: {
                    host: ''
                },
                activityTranslates: [],
                langs: [],
                seoIndex: '0',
                newFiles: []
            },
            dialogFormVisible: false,
            copyLangs: [],
            copyLangList: [],
            copyCheckAll: false,
            copyIsIndeterminate: false,
            currentUrl: '',
            myTenantid:null,
        };
    },
    created() {
        this.handleCheckedCitiesChange(this.formListQuery.bannerCountriesList);
        this.initTenantId();
        this.getDictItems();
        this.initImg();
    },
    methods: {
        initTenantId(){
            this.myTenantid = scene.getTenantId()
            console.log(this.myTenantid);
        },
        syncLang(copyUrl) {
            this.dialogFormVisible = true;
            this.copyLangs = this.copyLangList.map(value => value.code);
            this.copyCheckAll = true;
            this.currentUrl = copyUrl;
        },
        onCopySubmit() {
            if (this.copyLangs.length == 0) {
                this.$message({
                    type: 'warning',
                    message: '请选择要同步的语言!'
                });
                return;
            }
            this.dialogFormVisible = false;
            this.copyLangs.forEach((item) => {
                this.formListQuery.bannerFiles.forEach((item2) => {
                    if (item === item2.langCode) {
                        if (this.formListQuery.bannerType === 'Picture') {
                            item2.imgUrl = this.currentUrl;
                        } else if (this.formListQuery.bannerType === 'Video') {
                            item2.videoUrl = this.currentUrl;
                        }
                    }
                });
            });
        },
        closeCopyDialog() {
            this.dialogFormVisible = false;
            this.copyLangs = [];
            this.copyIsIndeterminate = false;
        },
        handleCopyCheckAllChange(val) {
            this.copyLangs = val ? this.copyLangList.map((value) => value.code) : [];
            this.copyIsIndeterminate = false;
        },
        handleCopyCheckedCitiesChange(value) {
            let checkedCount = value.length;
            this.copyCheckAll = checkedCount === this.copyLangList.length;
            this.copyIsIndeterminate = checkedCount > 0 && checkedCount < this.copyLangList.length;
        },
        handleCheckAllChange(val) {
            this.formListQuery.bannerCountriesList = val ? this.dictionary.country.map((value) => value.code) : [];
            this.isIndeterminate = false;
        },
        handleCheckedCitiesChange(value) {
            let checkedCount = value.length;
            this.checkAll = checkedCount === this.dictionary.country.length;
            this.isIndeterminate = checkedCount > 0 && checkedCount < this.dictionary.country.length;
        },
        clearValue(val) {
            this.itemKey = Math.random(); //给key赋随机值
            let type = 'yyyy-MM-dd hh:mm:ss';
            if (!val) {
                this.$set(this.formListQuery, 'time', []);
                this.$set(this.formListQuery, 'beginTime', null);
                this.$set(this.formListQuery, 'endTime', null);
            } else {
                this.$set(this.formListQuery.time, 0, val[0]);
                this.$set(this.formListQuery.time, 1, val[1]);
            }
        },
        salaryChange(e) {
            this.formListQuery.sort = e.target.value;
        },
        initImg() {
            console.log(this.formListQuery);
            this.aigoBrandStatus = false;
            this.noblezaBrandStatus = false;
            this.langCodes = this.dictionary.lang;
            
            if (!this.isEdit) {
                this.formListQuery.bannerFiles = [];
                console.log(123321, this.langCodes);
                this.langCodes.forEach((item, index) => {
                    let data = {
                        bannerFileId: null,
                        bannerId: this.bannerId,
                        langCode: item.code,
                        imgUrl: '',
                        jumpUrl: '',
                        videoUrl: '',
                        name: item.name
                    };
                    this.formListQuery.bannerFiles.push(data);
                });
            } else {
                this.langCodes.forEach((item, index) => {
                    this.formListQuery.bannerFiles.forEach((item2, index2) => {
                        if (item.code == item2.langCode) {
                            this.formListQuery.bannerFiles[index2].name = item.name;
                        }
                    });
                });
            }
            if (this.formListQuery.bannerPosition == 'OurBrand') {
                this.noblezaBrandStatus = true;
                this.dictionary.type.splice(1, 1);
            } else {
                this.dictionary.type = [
                    {
                        id: '1',
                        value: 'Picture',
                        name: '图片'
                    },
                    {
                        id: '2',
                        value: 'Video',
                        name: '视频'
                    }
                ];
            }
            this.formListQuery.bannerFiles.forEach((item, index) => {
                this.formListQuery.bannerFiles[index].sort = index;
            });
            console.log('this.formListQuery.bannerFiles', this.formListQuery.bannerFiles);
            if(this.myTenantid == 1001002001){
                this.$set(this.formListQuery,'brandCode','Unico');
            }
            console.log(this.formListQuery);
        },
        //改变跳转类型
        changeBannerJump(val) {
            this.$set(this.formListQuery, 'bannerJumpType', val);
        },
        //选择位置
        changeBannerPosition(val) {
            this.aigoBrandStatus = false;
            this.noblezaBrandStatus = false;
            if (val == 'HomeRecommended' || val == 'StarProducts' ) {
                let type = [
                    {
                        id: '1',
                        value: 'Picture',
                        name: '图片'
                    }
                ];
                this.$set(this.dictionary, 'type', type);
                this.$set(this.formListQuery, 'bannerType', 'Picture');
            } else if (val == 'HomePage' || val == 'CompanyProducts' || val == 'ContactUs' || val == 'PromotionZone' ) {
                let type = [
                    {
                        id: '1',
                        value: 'Picture',
                        name: '图片'
                    },
                    {
                        id: '2',
                        value: 'Video',
                        name: '视频'
                    }
                ];
                this.$set(this.dictionary, 'type', type);
            }
            else if (val == 'OurBrand'){
                let type = [
                    {
                        id: '1',
                        value: 'Picture',
                        name: '图片'
                    }
                ];
                this.$set(this.dictionary, 'type', type);
                this.$set(this.formListQuery, 'bannerType', 'Picture');
                this.noblezaBrandStatus = true;
                if(this.myTenantid == 1001002001){
                    this.$set(this.formListQuery,'brandCode','Unico')
                }else{
                    this.$set(this.formListQuery,'brandCode','AigoStar')
                }
                
            }
        },
        changeCannerType(val) {
            console.log('va', val, this.formListQuery.bannerPosition, this.formListQuery);
            if (val == 'Picture') {
                this.$set(this.formListQuery, 'bannerType', 'Picture');
            } else if (val == 'Video') {
                this.$set(this.formListQuery, 'bannerType', 'Video');
            }
            this.formListQuery.bannerFiles.forEach((item2, index2) => {
                item2.videoUrl = ''
                item2.imgUrl =''
            });
        },
        //获取OSS签名
        getOssSignature() {
            this.$store
                .dispatch('getSignature', {
                    dir: 'b2b'
                })
                .then((res) => {
                    this.ossInfo.ossSignature = res;
                    this.ossInfo.ossSignature.host = this.ossInfo.ossHost;
                });
        },
        upLoadSuccess() {
            // 拿到upload的dom
            this.$refs.uploadImg[this.activeIndex].clearFiles();
            this.$forceUpdate();
            console.log('Success', this.$refs.uploadImg[this.activeIndex]);
        },
        handleFormData(val) {
            this.formListQuery = val;
        },
        handleChange(file, fileLis) {
            console.log('file', file);
            const fileType = ['image/jpg', 'image/jpeg', 'image/png', 'image/gif'];
            if (fileType.indexOf(file.raw.type) == -1) {
                this.$message.error('上传图片只支持jpg、png、gif、jpeg');
                return;
            }
            if (file.size > 1024 * 1024 * 5) {
                this.$message.error('上传图片大小不能超过 5MB!');
                return;
            }
            let dataObj = {
                name: file.name,
                key: this.ossInfo.ossSignature.dir + '/' + new Date().getFullYear() + '/' + this.randomString() + this.getSuffix(file.name),
                policy: this.ossInfo.ossSignature['policy'],
                OSSAccessKeyId: this.ossInfo.ossSignature['access_id'],
                success_action_status: '201',
                callback: '',
                signature: this.ossInfo.ossSignature['signature']
                // file: file.file,
            };
            file.type = 'add';
            console.log('file.response', file.response);
            this.fileDataObj = dataObj;
            setTimeout(() => {
                console.log('%%%%%%', this.$refs.uploadImg[this.activeIndex]);
                this.$refs.uploadImg[this.activeIndex].submit();
                let x2js = new X2JS();
                let jsonObj = x2js.xml2js(file.response);
                console.log(jsonObj);
                let ossUrl = jsonObj.PostResponse.Location;
                this.imageUrl = ossUrl;
                this.formListQuery.bannerFiles.forEach((item, index) => {
                    if (item.langCode === this.langCode) {
                        this.$set(this.formListQuery.bannerFiles[index], 'imgUrl', ossUrl);
                        this.$set(this.formListQuery.bannerFiles[index], 'videoUrl', '');
                        this.$set(this.formListQuery.bannerFiles[index], 'langCode', this.langCode);
                    }
                });

                console.log('this.formListQuery.bannerFiles', this.formListQuery.bannerFiles, this.langCode);
            }, 500);
        },
        handleChange2(file, fileLis) {
            const fileType = ['video/mp4'];
            if (fileType.indexOf(file.raw.type) == -1) {
                this.$message.error('上传视频只支持mp4');
                return;
            }
            if (file.size > 1024 * 1024 * 500) {
                this.$message.error('上传视频大小不能超过 500MB!');
                return;
            }
            let dataObj = {
                name: file.name,
                key: this.ossInfo.ossSignature.dir + '/' + new Date().getFullYear() + '/' + this.randomString() + this.getSuffix(file.name),
                policy: this.ossInfo.ossSignature['policy'],
                OSSAccessKeyId: this.ossInfo.ossSignature['access_id'],
                success_action_status: '201',
                callback: '',
                signature: this.ossInfo.ossSignature['signature']
                // file: file.file,
            };
            file.type = 'add';
            this.fileDataObj2 = dataObj;
            setTimeout(() => {
                this.$refs.uploadVideo.submit();
                let x2js = new X2JS();
                console.log('file.response', file.response);
                let jsonObj = x2js.xml2js(file.response);
                console.log(jsonObj);
                let ossUrl = jsonObj.PostResponse.Location;
                this.ossVideoUrl = ossUrl;
                this.formListQuery.bannerFiles.forEach((item, index) => {
                    if (item.langCode === this.langCode) {
                        this.$set(this.formListQuery.bannerFiles[index], 'imgUrl', '');
                        this.$set(this.formListQuery.bannerFiles[index], 'videoUrl', this.ossVideoUrl);
                        this.$set(this.formListQuery.bannerFiles[index], 'countryCode', this.langCode);
                    }
                });
            }, 500);
        },
        /**
         * 生成随机文件名
         * @param len
         * @returns {string}
         */
        randomString(len) {
            len = len || 32;
            let chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';
            let maxPos = chars.length;
            let pwd = '';
            for (let i = 0; i < len; i++) {
                pwd += chars.charAt(Math.floor(Math.random() * maxPos));
            }
            return pwd;
        },

        getSuffix(filename) {
            let pos = filename.lastIndexOf('.');
            let suffix = '';
            if (pos != -1) {
                suffix = filename.substring(pos);
            }
            return suffix;
        },
        handleAvatarSuccessVideo(res, file) {
            var timestamp = new Date().getTime();
            var fileName = timestamp + '_' + parseInt(Math.round(Math.random() * 100));
            let dataObj = {
                name: file.name,
                key: this.ossInfo.ossSignature.dir + '/fulltext/' + fileName + this.getSuffix(file.name),
                policy: this.ossInfo.ossSignature['policy'],
                OSSAccessKeyId: this.ossInfo.ossSignature['access_id'],
                success_action_status: '201',
                callback: '',
                signature: this.ossInfo.ossSignature['signature'],
                file: file
            };

            this.$store
                .dispatch('postOss', {
                    params: dataObj,
                    url: this.ossInfo.host
                })
                .then((res) => {
                    //解析xml
                    let x2js = new X2JS();
                    let jsonObj = x2js.xml2js(res);
                    let ossName = jsonObj.PostResponse.Key;
                    this.videoUrl = URL.createObjectURL(file.raw);
                    this.ossVideoUrl = jsonObj.PostResponse.Location;
                });
        },
        uploadVideoProcess(event, file, fileList) {
            this.videoFlag = true;
            this.videoUploadPercent = parseInt(file.percentage.toFixed(0));
        },
        handleClick(val) {
            console.log('val', val);
            this.langCode = val.name;
            this.showFiles = true;
        },
        getActiveIndex(index) {
            this.activeIndex = index;
        },
        onSubmit() {
            this.onSubmit1('variantFrom');
        },
        //提交
        onSubmit1(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    if (this.formListQuery.bannerType == 'Picture') {
                        let flag = true;
                        for (const item of this.formListQuery.bannerFiles) {
                            if (item.imgUrl !== '') {
                                console.log('index', item);
                                flag = false;
                                break;
                            }
                        }
                        if (flag) {
                            this.$message({
                                message: '至少需要上传一个语言图片',
                                type: 'warning',
                                duration: 1000
                            });
                            return;
                        }
                    } else if (this.formListQuery.bannerPosition != 'HomeRecommended' && this.formListQuery.bannerType == 'Video') {
                        let flag = true;
                        for (const item of this.formListQuery.bannerFiles) {
                            if (item.videoUrl !== '') {
                                console.log('index', item);
                                flag = false;
                                break;
                            }
                        }
                        if (flag) {
                            this.$message({
                                message: '至少需要上传一个语言视频',
                                type: 'warning',
                                duration: 1000
                            });
                            return;
                        }
                    }

                    if (this.formListQuery.bannerJumpType === 'Link') {
                        let flag = true;
                        let flagReg = true;
                        let reg = /(https?|ftp|file):\/\/[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]/;
                        this.formListQuery.bannerFiles.forEach((item, index) => {
                            if (item.jumpUrl !== '') {
                                console.log('index', index);
                                flag = false;
                                if (!reg.test(item.jumpUrl)) {
                                    flagReg = false;
                                }
                            }
                        });
                        if (flag) {
                            this.$message({
                                message: '至少需要上传一个语言跳转链接',
                                type: 'warning',
                                duration: 1000
                            });
                            return;
                        }
                        if (flagReg == false) {
                            this.$message({
                                message: '请输入正确的链接',
                                type: 'warning',
                                duration: 1000
                            });
                            return;
                        }
                    } else {
                        if (this.formListQuery.bannerFiles.length > 0) {
                            this.formListQuery.bannerFiles.forEach((item, index) => {
                                this.formListQuery.bannerFiles[index].jumpUrl = '';
                            });
                        }
                    }

                    this.$confirm('是否提交数据', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        this.formListQuery.beginTime = moment(this.formListQuery.time[0]).unix() * 1000;
                        this.formListQuery.endTime = moment(this.formListQuery.time[1]).unix() * 1000;
                        console.log('this.formListQuery.time', this.formListQuery);
                        this.formListQuery.bannerCountries = this.formListQuery.bannerCountriesList.map((item) => {
                            return {
                                countryCode: item
                            };
                        });
                        if (this.formListQuery.bannerJumpType === 'None') {
                            this.formListQuery.bannerFiles.forEach((item, index) => {
                                if (item.langCode === this.langCode) {
                                    this.formListQuery.bannerFiles[index].jumpUrl = '';
                                }
                            });
                        }
                        if (this.isEdit) {
                            if (this.formListQuery.bannerType == 'Picture') {
                                this.formListQuery.bannerFiles.forEach((item, index) => {
                                    this.formListQuery.bannerFiles[index].videoUrl = '';
                                });
                            } else if (this.formListQuery.bannerType == 'Video' && this.formListQuery.bannerPosition != 'ContactUs') {
                                this.formListQuery.bannerFiles.forEach((item, index) => {
                                    this.formListQuery.bannerFiles[index].imgUrl = '';
                                });
                            }
                            this.$store.dispatch('updateBanner', this.formListQuery).then((res) => {
                                this.$message({
                                    message: '提交成功',
                                    type: 'success',
                                    duration: 1000
                                });
                                this.closeDialog();
                                this.handleSearchList();
                            });
                        } else {
                            this.formListQuery.bannerId = '';
                            this.formListQuery.bannerId = this.bannerId;
                            if (this.formListQuery.bannerType == 'Picture') {
                                this.formListQuery.bannerFiles.forEach((item, index) => {
                                    this.formListQuery.bannerFiles[index].videoUrl = '';
                                });
                            } else if (this.formListQuery.bannerType == 'Video'  && this.formListQuery.bannerPosition != 'ContactUs' ) {
                                this.formListQuery.bannerFiles.forEach((item, index) => {
                                    this.formListQuery.bannerFiles[index].imgUrl = '';
                                });
                            }
                            console.log('this.formListQuery', this.formListQuery);
                            this.$store.dispatch('addBanner', this.formListQuery).then((res) => {
                                this.$message({
                                    message: '提交成功',
                                    type: 'success',
                                    duration: 1000
                                });
                                this.closeDialog();
                                this.handleSearchList();
                            });
                        }
                    });
                } else {
                    this.$message({
                        message: '验证失败，请先输入必填项',
                        type: 'error',
                        duration: 1000
                    });
                    return false;
                }
            });
        },
        //关闭视频
        backVideo() {
            this.videoUploadPercent = 0;
            this.ossVideoUrl = '';
        },
        //关闭窗口
        closeDialog() {
            this.videoUploadPercent = 0;
            this.$emit('closeDialog');
        },
        handleSearchList() {
            this.$emit('handleSearchList');
        },
        getDictItems() {
            var _this = this;
            this.$store.dispatch('getDicItems', { tag: 'lang' }).then((res) => {
                if (res != null){
                    _this.copyLangList = res;
                    console.log(this.copyLangList);
                }
            });
            
        },
    },
    mounted() {
        this.getDictItems();
        this.getOssSignature();
        this.initImg();
    }
};
</script>
<style scoped>
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    width: 178px !important;
    height: 178px !important;
}

.avatar-uploader .el-upload:hover {
    border-color: #409eff;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
}

/deep/ .avatar-uploader .el-upload--picture-card {
    width: 178px !important;
    height: 178px !important;
}

/deep/ .avatar-uploader .el-progress--circle {
    width: 176px !important;
    height: 176px !important;
    margin-top: 0px !important;
}

/deep/ .avatar-uploader .el-progress {
    width: 176px !important;
    height: 176px !important;
    margin-top: 0px !important;
}

/deep/ .avatar-uploader .el-progress-circle {
    width: 176px !important;
    height: 176px !important;
    margin-top: 0px !important;
}

.avatar {
    height: 178px;
    display: block;
}

.uploadForm {
    width: 120px;
    text-align: end;
    padding-right: 12px;
    box-sizing: border-box;
}

.uploadTitle {
    width: 120px;
    text-align: end;
    padding-right: 12px;
    box-sizing: border-box;
}

.uploadTitle:before {
    content: '*';
    color: #f56c6c;
    margin-right: 4px;
}

.input-width {
    width: 70%;
}

.dialog-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}

.prpa {
    display: flex;
    justify-content: center;
    align-items: center;
}

.prpa-sub {
    border: 1px solid #000000;
    width: 80%;
}

.content1 {
    width: auto;
    height: 100%;
    overflow-y: scroll;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.content-sub-left {
    cursor: pointer;
    position: relative;
    background-color: #ececec;
    width: 40%;
}

.content-sub-right {
    width: 60%;
}

.content-sub-item {
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    padding: 10px 0px 10px 10px;
}

.active::before {
    content: '';
    position: absolute;
    border-left: 4px solid #55aaff;
    height: 30px;
    left: 0px;
}

.active {
    font-weight: 900;
    font-style: normal;
    font-size: 20px;
    background-color: #46a8a0;
    color: white;
}

.content2 {
    width: auto;
    height: 100%;
}

.content2-sub {
    font-size: 15px;
    color: #464646;
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
</style>
