var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "variantFrom",
          attrs: { model: _vm.formListQuery, rules: _vm.rules }
        },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: "名称",
                prop: "title",
                "label-width": _vm.formLabelWidth
              }
            },
            [
              _c("el-input", {
                staticClass: "input-width",
                attrs: { placeholder: "名称" },
                model: {
                  value: _vm.formListQuery.title,
                  callback: function($$v) {
                    _vm.$set(_vm.formListQuery, "title", $$v)
                  },
                  expression: "formListQuery.title"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "品牌",
                prop: "brandCode",
                "label-width": _vm.formLabelWidth
              }
            },
            [
              _c(
                "el-select",
                {
                  staticClass: "filter-item",
                  staticStyle: { width: "180px" },
                  attrs: { placeholder: "品牌", clearable: "" },
                  model: {
                    value: _vm.formListQuery.brandCode,
                    callback: function($$v) {
                      _vm.$set(_vm.formListQuery, "brandCode", $$v)
                    },
                    expression: "formListQuery.brandCode"
                  }
                },
                [
                  _vm.myTenantid == "1001001000" ||
                  _vm.myTenantid == "1001001006"
                    ? _c(
                        "el-option",
                        {
                          attrs: {
                            disabled: _vm.aigoBrandStatus,
                            value: "AigoStar"
                          }
                        },
                        [_vm._v("AigoStar")]
                      )
                    : _vm._e(),
                  _vm.myTenantid == "1001001000" ||
                  _vm.myTenantid == "1001001006"
                    ? _c(
                        "el-option",
                        {
                          attrs: {
                            disabled: _vm.noblezaBrandStatus,
                            value: "Nobleza"
                          }
                        },
                        [_vm._v("Nobleza")]
                      )
                    : _vm._e(),
                  _vm.myTenantid == "1001002001"
                    ? _c("el-option", { attrs: { value: "Unico" } }, [
                        _vm._v("Unico")
                      ])
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "位置",
                prop: "bannerPosition",
                "label-width": _vm.formLabelWidth
              }
            },
            [
              _c("dataSelect", {
                attrs: { codeType: "banner_position", clearable: "" },
                on: { change: _vm.changeBannerPosition },
                model: {
                  value: _vm.formListQuery.bannerPosition,
                  callback: function($$v) {
                    _vm.$set(_vm.formListQuery, "bannerPosition", $$v)
                  },
                  expression: "formListQuery.bannerPosition"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "类型",
                prop: "bannerType",
                "label-width": _vm.formLabelWidth
              }
            },
            [
              _c(
                "el-radio-group",
                {
                  on: { change: _vm.changeCannerType },
                  model: {
                    value: _vm.formListQuery.bannerType,
                    callback: function($$v) {
                      _vm.$set(_vm.formListQuery, "bannerType", $$v)
                    },
                    expression: "formListQuery.bannerType"
                  }
                },
                _vm._l(_vm.dictionary.type, function(item, index) {
                  return _c(
                    "el-radio",
                    { key: item.id, attrs: { label: item.value } },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(item.name) +
                          "\n                "
                      )
                    ]
                  )
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "国家",
                prop: "bannerCountriesList",
                "label-width": _vm.formLabelWidth
              }
            },
            [
              _c(
                "el-checkbox",
                {
                  attrs: { indeterminate: _vm.isIndeterminate },
                  on: { change: _vm.handleCheckAllChange },
                  model: {
                    value: _vm.checkAll,
                    callback: function($$v) {
                      _vm.checkAll = $$v
                    },
                    expression: "checkAll"
                  }
                },
                [_vm._v("全选\n            ")]
              ),
              _c(
                "el-checkbox-group",
                {
                  on: { change: _vm.handleCheckedCitiesChange },
                  model: {
                    value: _vm.formListQuery.bannerCountriesList,
                    callback: function($$v) {
                      _vm.$set(_vm.formListQuery, "bannerCountriesList", $$v)
                    },
                    expression: "formListQuery.bannerCountriesList"
                  }
                },
                _vm._l(_vm.dictionary.country, function(item, index) {
                  return _c(
                    "el-checkbox",
                    { key: item.code, attrs: { label: item.code } },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(item.name) +
                          "\n                "
                      )
                    ]
                  )
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "展示时间",
                prop: "time",
                "label-width": _vm.formLabelWidth
              }
            },
            [
              _c("el-date-picker", {
                key: _vm.itemKey,
                attrs: {
                  clearable: "",
                  type: "datetimerange",
                  "range-separator": "至",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期"
                },
                on: { input: _vm.clearValue },
                model: {
                  value: _vm.formListQuery.time,
                  callback: function($$v) {
                    _vm.$set(_vm.formListQuery, "time", $$v)
                  },
                  expression: "formListQuery.time"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "跳转类型",
                prop: "bannerJumpType",
                "label-width": _vm.formLabelWidth
              }
            },
            [
              _c(
                "el-radio-group",
                {
                  on: { change: _vm.changeBannerJump },
                  model: {
                    value: _vm.formListQuery.bannerJumpType,
                    callback: function($$v) {
                      _vm.$set(_vm.formListQuery, "bannerJumpType", $$v)
                    },
                    expression: "formListQuery.bannerJumpType"
                  }
                },
                _vm._l(_vm.dictionary.jump, function(item, index) {
                  return _c(
                    "el-radio",
                    { key: item.id, attrs: { label: item.value } },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(item.name) +
                          "\n                "
                      )
                    ]
                  )
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "是否启用",
                prop: "status",
                "label-width": _vm.formLabelWidth
              }
            },
            [
              _c("el-switch", {
                attrs: {
                  "active-color": "#13ce66",
                  "inactive-color": "#ff4949"
                },
                model: {
                  value: _vm.formListQuery.status,
                  callback: function($$v) {
                    _vm.$set(_vm.formListQuery, "status", $$v)
                  },
                  expression: "formListQuery.status"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "排序",
                prop: "sort",
                "label-width": _vm.formLabelWidth
              }
            },
            [
              _c("el-input", {
                staticClass: "input-width",
                attrs: {
                  oninput: "value=value.replace(/[^0-9]/g,'')",
                  placeholder: "默认为0,数字越大,排序越靠前"
                },
                on: { blur: _vm.salaryChange },
                model: {
                  value: _vm.formListQuery.sort,
                  callback: function($$v) {
                    _vm.$set(_vm.formListQuery, "sort", $$v)
                  },
                  expression: "formListQuery.sort"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: false,
                  expression: "false"
                }
              ],
              attrs: { "label-width": _vm.formLabelWidth }
            },
            [
              _c("dataSelect", {
                attrs: { codeType: "lang", clearable: "" },
                model: {
                  value: _vm.formListQuery.langCode,
                  callback: function($$v) {
                    _vm.$set(_vm.formListQuery, "langCode", $$v)
                  },
                  expression: "formListQuery.langCode"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-tabs",
        {
          on: { "tab-click": _vm.handleClick },
          model: {
            value: _vm.activeName,
            callback: function($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName"
          }
        },
        _vm._l(_vm.formListQuery.bannerFiles, function(item, index) {
          return _c(
            "el-tab-pane",
            {
              key: item.langCode,
              attrs: { label: item.name, name: item.langCode }
            },
            [
              _c("div", [
                _vm.formListQuery.bannerType == "Picture"
                  ? _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "justify-content": "flex-start"
                        }
                      },
                      [
                        _c("div", { staticClass: "uploadTitle" }, [
                          _vm._v("图片")
                        ]),
                        _c("image-upload", {
                          attrs: {
                            formListQuery: _vm.formListQuery,
                            index: index,
                            langCode: _vm.langCode
                          },
                          on: { handleFormData: _vm.handleFormData }
                        }),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.syncLang(item.imgUrl)
                              }
                            }
                          },
                          [_vm._v("同步到其他语言")]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.formListQuery.bannerPosition != "HomeRecommended" &&
                _vm.formListQuery.bannerType == "Video"
                  ? _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "justify-content": "flex-start"
                        }
                      },
                      [
                        _c("div", { staticClass: "uploadTitle" }, [
                          _vm._v("视频")
                        ]),
                        _c("video-upload", {
                          attrs: {
                            formListQuery: _vm.formListQuery,
                            index: index,
                            langCode: _vm.langCode
                          },
                          on: { handleFormData: _vm.handleFormData }
                        }),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.syncLang(item.videoUrl)
                              }
                            }
                          },
                          [_vm._v("同步到其他语言")]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.formListQuery.bannerPosition == "ContactUs" &&
                _vm.formListQuery.bannerType == "Video"
                  ? _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "justify-content": "flex-start",
                          "margin-top": "20px"
                        }
                      },
                      [
                        _c("div", { staticClass: "uploadForm" }, [
                          _vm._v("上传封面")
                        ]),
                        _c("image-upload", {
                          attrs: {
                            formListQuery: _vm.formListQuery,
                            index: index,
                            langCode: _vm.langCode,
                            divShow: false,
                            maxSize: 3,
                            acceptType: ".jpg, .jpeg, .png"
                          },
                          on: { handleFormData: _vm.handleFormData }
                        }),
                        _c("div", { staticStyle: { "margin-left": "20px" } }, [
                          _c("div", [_vm._v("推荐尺寸：1920*1080")]),
                          _c("div", [_vm._v("文件类型：JPG，JPEG，PNG")]),
                          _c("div", [_vm._v("文件大小：≤3MB")])
                        ])
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "div",
                  {
                    staticStyle: {
                      display: "flex",
                      "justify-content": "flex-start",
                      "margin-top": "20px"
                    }
                  },
                  [
                    _c("div", { staticClass: "uploadForm" }, [_vm._v("标题")]),
                    _c("el-input", {
                      staticClass: "input-width",
                      attrs: {
                        placeholder: "标题",
                        maxlength: "500",
                        "show-word-limit": ""
                      },
                      model: {
                        value: item.title,
                        callback: function($$v) {
                          _vm.$set(item, "title", $$v)
                        },
                        expression: "item.title"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.formListQuery.bannerJumpType === "Link",
                        expression: "formListQuery.bannerJumpType === 'Link'"
                      }
                    ],
                    staticStyle: {
                      display: "flex",
                      "justify-content": "flex-start",
                      "margin-top": "20px"
                    }
                  },
                  [
                    _c("div", { staticClass: "uploadTitle" }, [
                      _vm._v("跳转链接")
                    ]),
                    _c("el-input", {
                      staticClass: "input-width",
                      attrs: { placeholder: "输入跳转链接" },
                      model: {
                        value: item.jumpUrl,
                        callback: function($$v) {
                          _vm.$set(item, "jumpUrl", $$v)
                        },
                        expression: "item.jumpUrl"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value:
                          _vm.formListQuery.bannerPosition ===
                          "CompanyProducts",
                        expression:
                          "formListQuery.bannerPosition === 'CompanyProducts'"
                      }
                    ],
                    staticStyle: {
                      display: "flex",
                      "justify-content": "flex-start",
                      "margin-top": "20px"
                    }
                  },
                  [
                    _c("div", { staticClass: "uploadForm" }, [_vm._v("内容")]),
                    _c("el-input", {
                      staticClass: "input-width",
                      attrs: {
                        type: "textarea",
                        placeholder: "输入内容",
                        maxlength: _vm.maxlengthTextarea,
                        "show-word-limit": "",
                        autosize: { minRows: 5, maxRows: 10 }
                      },
                      model: {
                        value: item.description,
                        callback: function($$v) {
                          _vm.$set(item, "description", $$v)
                        },
                        expression: "item.description"
                      }
                    })
                  ],
                  1
                )
              ]),
              _c(
                "el-form",
                { attrs: { model: _vm.formListQuery, rules: _vm.rules } },
                [
                  _c(
                    "el-form-item",
                    { staticStyle: { width: "20%", display: "none" } },
                    [
                      _c(
                        "div",
                        { staticClass: "dialog-footer" },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function($event) {
                                  return _vm.onSubmit1("variantFrom")
                                }
                              }
                            },
                            [_vm._v("确 定")]
                          )
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        }),
        1
      ),
      _c(
        "div",
        { staticClass: "dialog-footer" },
        [
          _c(
            "el-button",
            { staticStyle: { width: "20%" }, on: { click: _vm.closeDialog } },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              staticStyle: { width: "20%" },
              attrs: { type: "primary" },
              on: { click: _vm.onSubmit }
            },
            [_vm._v("确 定")]
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "同步到其他语言",
            visible: _vm.dialogFormVisible,
            "append-to-body": true,
            "before-close": _vm.closeCopyDialog,
            "close-on-click-modal": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogFormVisible = $event
            }
          }
        },
        [
          _c(
            "div",
            [
              _c(
                "el-checkbox",
                {
                  attrs: { indeterminate: _vm.copyIsIndeterminate },
                  on: { change: _vm.handleCopyCheckAllChange },
                  model: {
                    value: _vm.copyCheckAll,
                    callback: function($$v) {
                      _vm.copyCheckAll = $$v
                    },
                    expression: "copyCheckAll"
                  }
                },
                [_vm._v("全选\n            ")]
              ),
              _c(
                "el-checkbox-group",
                {
                  on: { change: _vm.handleCopyCheckedCitiesChange },
                  model: {
                    value: _vm.copyLangs,
                    callback: function($$v) {
                      _vm.copyLangs = $$v
                    },
                    expression: "copyLangs"
                  }
                },
                _vm._l(_vm.copyLangList, function(item, index) {
                  return _c(
                    "el-checkbox",
                    { key: item.code, attrs: { label: item.code } },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(item.name) +
                          "\n                "
                      )
                    ]
                  )
                }),
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "dialog-footer" },
            [
              _c(
                "el-button",
                {
                  staticStyle: { width: "20%" },
                  on: { click: _vm.closeCopyDialog }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  staticStyle: { width: "20%" },
                  attrs: { type: "primary" },
                  on: { click: _vm.onCopySubmit }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }