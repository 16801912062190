<template>
    <div class="app-container">
        <div class="filter-container">
            <el-input
                v-model="query.name"
                placeholder="名称"
                style="width: 200px"
                class="filter-item"
                @keyup.enter.native="handleQuery"
                clearable
                oninput="value=value.replace(/[%]/g,'')"
            />
            <dataSelect
                style="width: 200px"
                class="filter-item"
                v-model="query.brand"
                clearable
                :codeType="'brand'"
                placeholder="品牌"
            ></dataSelect>
            <dataSelect
                style="width: 200px"
                class="filter-item"
                v-model="query.lang"
                clearable
                :codeType="'lang'"
                placeholder="语言"
            ></dataSelect>

            <el-button class="filter-item" icon="el-icon-search" type="primary" @click="handleQuery"> 查询 </el-button>
            <el-button class="filter-item" icon="el-icon-search" type="primary" @click="cleanQuery" style="margin-left: 10px" size="small ">
                重置
            </el-button>

            <div style="margin-bottom: 20px">
                <el-button class="filter-item" type="success" icon="el-icon-plus" @click="handleCreate" style="margin-top: 20px"
                    >添加</el-button
                >
                <el-button class="filter-item" icon="el-icon-download" type="warning" @click="handleExport"> 导出 </el-button>
                <el-button icon="el-icon-upload2" type="primary" @click="handleImport"> 导入 </el-button>
                <el-button
                    @click="downloadTemplate"
                    type="text"
                    size="mini"
                    style="margin-left: 5px; vertical-align: -7px; text-decoration: underline"
                    >下载导入模板</el-button
                >
            </div>
        </div>

        <el-table v-loading="listLoading" :data="list" @selection-change="selectionChange" border stripe style="width: 100%">
            <el-table-column label="Id" prop="id" width="250px" align="center">
                <template slot-scope="{ row }">
                    <span>{{ row.id }}</span>
                </template>
            </el-table-column>
            <el-table-column label="品牌" prop="brand" align="center">
                <template slot-scope="{ row }">
                    <span>{{ row.brand }}</span>
                </template>
            </el-table-column>
            <el-table-column label="名称" prop="name" align="center">
                <template slot-scope="{ row }">
                    <span>{{ row.name }}</span>
                </template>
            </el-table-column>

            <el-table-column label="页面URL" prop="url" align="center">
                <template slot-scope="{ row }">
                    <span>{{ row.url }}</span>
                </template>
            </el-table-column>
            <el-table-column label="语言" prop="lang" width="90px" align="center">
                <template slot-scope="{ row }">
                    <span>{{ $dict.getDictValue('lang', row.lang) }}</span>
                </template>
            </el-table-column>
            <el-table-column label="页面标题" prop="title" align="center">
                <template slot-scope="{ row }">
                    <span>{{ row.title }}</span>
                </template>
            </el-table-column>
            <el-table-column label="页面关键字" prop="keyword" width="200px" align="center">
                <template slot-scope="{ row }">
                    <span>{{ row.keyWord }}</span>
                </template>
            </el-table-column>
            <el-table-column label="页面描述" prop="description" align="center">
                <template slot-scope="{ row }">
                    <span>{{ row.description }}</span>
                </template>
            </el-table-column>
            <el-table-column label="创建时间" prop="createtime" align="center">
                <template slot-scope="{ row }">
                    <span>{{ new Date(row.createTime).toLocaleString() }}</span>
                </template>
            </el-table-column>
            <el-table-column label="更新时间" prop="updatetime" align="center">
                <template slot-scope="{ row }">
                    <span v-if="row.updateTime != null">{{ new Date(row.updateTime).toLocaleString() }}</span>
                </template>
            </el-table-column>
            <el-table-column label="操作" align="center" width="200">
                <template slot-scope="{ row }">
                    <el-button type="primary" size="mini" @click="handleUpdate(row)" class="table-inner-button">编辑</el-button>
                    <el-button type="danger" size="mini" @click="handleDeleteSingle(row)" class="table-inner-button">删除</el-button>
                </template>
            </el-table-column>
        </el-table>

        <pagination v-show="total > 0" :total="total" :pageNo.sync="query.current" :pageSize.sync="query.size" @pagination="doQueryList" />

        <!-- 新建编辑表单 -->
        <addOrUpdate ref="seoAdd" @created="doQueryList({ pageNo: 1 })" />

        <seoImport ref="seoImport" @imported="doQueryList({ pageNo: 1 })" />
    </div>
</template>

<script>
import addOrUpdate from './addOrUpdate';
import seoImport from './import';
import Pagination from '@/components/common/Pagination';
import DataSelect from '@/components/common/DataSelect';

export default {
    name: 'SeoTable',
    components: {
        Pagination,
        seoImport,
        DataSelect,
        addOrUpdate
    },
    data() {
        return {
            list: [],
            total: 0,
            listLoading: true,
            query: {
                current: 1,
                size: 10,
                sydelete: null,
                name: null,
                brand: null,
                url: null,
                lang: null,
                title: null,
                keyword: null,
                description: null,
                createtimeStart: '',
                createtimeEnd: '',
                updatetimeStart: '',
                updatetimeEnd: '',
                systampStart: '',
                systampEnd: '',
                tenantid: undefined
            },
            selectItems: []
        };
    },
    created() {
        this.doQueryList({ pageNo: 1 });
    },
    methods: {
        /**
         * 选择框变化
         */
        selectionChange(val) {
            this.selectItems = val;
        },
        /**
         * 触发搜索操作
         */
        handleQuery() {
            this.doQueryList({ pageNo: 1 });
        },
        /**
         * 执行列表查询
         */
        doQueryList({ pageNo, pageSize }) {
            if (pageNo) {
                this.query.current = pageNo;
            }
            if (pageSize) {
                this.query.size = pageSize;
            }
            this.listLoading = true;

            this.$store
                .dispatch('getSeoPage', this.query)
                .then((res) => {
                    this.list = res.list;
                    this.total = res.total;
                })
                .finally(() => {
                    this.listLoading = false;
                });
        },
        /**
         * 删除单条记录
         */
        handleDeleteSingle(row) {
            return this.$confirm('是否确认继续删除数据?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$store.dispatch('deleteSEO', row.id).then((res) => {
                    this.$message({
                        type: 'success',
                        message: '删除成功'
                    });
                    this.doQueryList({ pageNo: 1 });
                });
            });
        },
        /**
         * 表单重置
         */
        cleanQuery() {
            this.query = [];
            this.doQueryList({ pageNo: 1 });
        },
        /**
         * 打开新建表单
         */
        handleCreate() {
            this.$refs.seoAdd.handleCreate();
            this.$refs.seoAdd.formTitle = '添加网站SEO';
        },
        /**
         * 打开编辑表单
         */
        handleUpdate(row) {
            this.$refs.seoAdd.handleCreate();
            this.$refs.seoAdd.form = JSON.parse(JSON.stringify(row));
            this.$refs.seoAdd.formTitle = '编辑网站SEO';
        },
        /**
         * 导出excel
         */
        handleExport() {
            this.$confirm('是否确认导出文件, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            })
                .then(() => {
                    this.$store.dispatch('exportSeoExcel', { fileName: 'SEO数据.xlsx' }).then((res) => {
                        this.$message({
                            type: 'success',
                            message: '导出成功'
                        });
                    });
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消'
                    });
                });
        },
        /**
         * 打开导入表单
         */
        handleImport() {
            this.$refs.seoImport.show();
        },
        /**
         * 下载模板
         */
        downloadTemplate() {
            this.$store.dispatch('downExcelTemplate', { fileName: 'SEO配置模板.xlsx' }).then((res) => {
                this.$message({
                    type: 'success',
                    message: '下载成功'
                });
            });
        }
    }
};
</script>

<style scoped>
.filter-container {
    padding: auto;
    margin-left: 20px;
    padding-top: 20px;
}
.filter-item {
    margin-right: 10px;
}
</style>
