<template>
    <div>
        <filter-container
            :arg="arg"
            :defaultListQuery="defaultListQuery"
            :placeholder="placeholder"
            :dictionary="dictionary"
            @handleSearchList="handleSearchList"
            @handleResetSearch="handleResetSearch"
        ></filter-container>
        <div style="margin: 20px 0px 20px 20px">
            <el-button @click="add" type="success" size="small">添加</el-button>
        </div>
        <el-table
            ref="pagedata"
            :data="pageData.list"
            style="width: 100%"
            border
            :header-cell-style="{ background: '#EAEEF9', color: '#32353a' }"
        >
            <el-table-column prop="bannerId" label="编号" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="bannerPosition" label="位置" align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    <!--					<span v-if="scope.row.bannerPosition=='HomeRecommended'">首页推荐位置</span>-->
                    <!--					<span v-else-if="scope.row.bannerPosition=='HomePage'">首页banner</span>-->
                    <dataText class="filter-item" v-model="scope.row.bannerPosition" clearable :codeType="'banner_position'"></dataText>
                </template>
            </el-table-column>
            <el-table-column prop="bannerType1" label="类型" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="brandCode" label="品牌" align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    <dataText v-model="scope.row.brandCode" :codeType="'brand'"></dataText>
                </template>
            </el-table-column>
            <el-table-column prop="bannerCountries" label="国家" align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span v-for="item in scope.row.bannerCountries" :key="item.bannerCountryId">
                        <dataText v-model="item.countryCode" :codeType="'country_ot'"></dataText
                    ></span>
                </template>
            </el-table-column>
            <el-table-column prop="title" label="名称" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="bannerFiles" label="图片/视频" align="center" width="130px">
                <template slot-scope="scope">
                    <el-image
                        style="width: 100px; height: 100px"
                        v-if="scope.row.bannerType === 'Picture' && scope.row.imgUrl1 && scope.row.imgUrl1.imgUrl"
                        :src="scope.row.imgUrl1.imgUrl"
                        fit="fill"
                    ></el-image>
                    <video
                        class="float-l"
                        style="width: 100px; height: 100px"
                        v-else-if="scope.row.bannerType === 'Video' && scope.row.videoUrl1 && scope.row.videoUrl1.videoUrl"
                        :src="scope.row.videoUrl1.videoUrl"
                        controls="controls"
                    >
                        您的浏览器不支持视频播放
                    </video>
                </template>
            </el-table-column>
            <el-table-column prop="bannerJumpType" label="跳转类型" align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span>{{ scope.row.bannerJumpType == 'Link' ? '有跳转' : '无跳转' }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="targetUrl" label="跳转地址" align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span v-if="scope.row.jumpUrl1 && scope.row.jumpUrl1.jumpUrl">{{ scope.row.jumpUrl1.jumpUrl }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="status1" label="状态" align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    <dataText
                        class="filter-item"
                        v-model="scope.row.status1"
                        :color="scope.row.statusColor"
                        clearable
                        :codeType="'activity_status'"
                    ></dataText>
                </template>
            </el-table-column>
            <el-table-column prop="title" label="是否启用" align="center">
                <template slot-scope="scope">
                    <el-switch v-model="scope.row.status" active-color="#13ce66" disabled> </el-switch>
                </template>
            </el-table-column>
            <el-table-column prop="beginTime" label="开始时间" align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span>{{ scope.row.beginTime | formatDate }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="endTime" label="结束时间" align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span>{{ scope.row.endTime | formatDate }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="sort" :render-header="renderSortHeader" align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    <el-input
                        v-model="scope.row.sort"
                        oninput="value=value.replace(/^\.+|[^\d]/g,'')"
                        @keyup.enter.native="putSortBanner(scope.row)"
                        placeholder="请输入内容"
                    ></el-input>
                </template>
            </el-table-column>
            <el-table-column prop="updateTime" label="更新时间" align="center">
                <template slot-scope="{ row }">
                    <span>{{ row.updateTime | formatDate }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="updateByName" label="操作人" align="center"></el-table-column>
            <el-table-column label="操作" align="center" fixed="right">
                <template slot-scope="scope">
                    <el-button @click="edit(scope.row)" type="text" size="small">编辑</el-button>
                    <el-button @click="handleDelete(scope.row)" type="text" size="small">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <div class="block">
            <el-pagination
                @current-change="handleCurrentChange"
                :current-page="listQuery.page"
                :page-size="listQuery.limit"
                layout="total, prev, pager, next, jumper"
                :total="pageData.total"
            >
            </el-pagination>
        </div>
        <el-dialog
            :title="isEdit ? '编辑banner' : '添加banner'"
            :visible.sync="dialogFormVisible"
            :before-close="closeDialog"
            :close-on-click-modal="false"
        >
            <div v-if="dialogFormVisible">
                <varoant-dialog
                    :isEdit="isEdit"
                    :formListQuery="formListQuery"
                    :bannerId="bannerId"
                    :dictionary="dictionary"
                    @closeDialog="closeDialog"
                    @handleSearchList="handleSearchList"
                />
            </div>
        </el-dialog>
    </div>
</template>
<script>
import { enumeration } from '@/utils/common2';
import { formatDate } from '@/utils/date';
import cache from '@/utils/cache';
import FilterContainer from '@/components/common/FilterContainer.vue';
import WangEditer from '@/components/common/WangEditer/index.vue';
import VaroantDialog from './components/VaroantDialog.vue';
import dataText from '@/components/common/DataText';
import dataSelect from '@/components/common/DataSelect.vue';
import moment from 'moment';

const defaultFormListQuery = {
    title: '',
    targetUrl: '',
    beginTime: '',
    endTime: '',
    sort: 0,
    brandCode: 'AigoStar',
    bannerJumpType: 'None',
    time: [],
    status: true,
    bannerType: 'Picture',
    bannerPosition: 'HomePage',
    goodsClassId: '',
    bannerCountries: [],
    bannerCountriesList: [],
    bannerFiles: [],
    langCode: null
};
const defaultDictionary = {
    //类型
    type: [
        {
            id: '1',
            value: 'Picture',
            name: '图片'
        },
        {
            id: '2',
            value: 'Video',
            name: '视频'
        }
    ],
    // 国家
    country: [],
    bannerPosition: [],
    brandCode: [],
    lang:[],
    jump: [{
        id: '1',
        name: '有跳转',
        value: 'Link'
    }, {
        id: '2',
        name: '无跳转',
        value: 'None'
    }],
    state: [{
        id: '1',
        name: '进行中',
        value: '1'
    }, {
        id: '2',
        name: '即将开始',
        value: '2'
    }, {
        id: '3',
        name: '已结束',
        value: '3'
    }],
    status: [{
        id: '1',
        name: '是',
        value: true
    }, {
        id: '2',
        name: '否',
        value: false
    }]
};
export default {
    components: {
        FilterContainer,
        VaroantDialog,
        WangEditer,
        dataText,
        moment,
        dataSelect
    },
    name: 'variant',
    data() {
        return {
            statusColor: '',
            listQuery: {
                beginTime: '',
                endTime: '',
                brandCode: '',
                bannerJumpType: '',
                status: '',
                countryCodes: [],
                bannerType: '',
                bannerPosition: '',
                goodsClassId: '',
                page: 1,
                limit: 10,
                desc: true
            },
            defaultListQuery: {
                beginTime: '',
                endTime: '',
                brandCode: '',
                bannerJumpType: '',
                status: '',
                countryCodes: [],
                bannerType: '',
                bannerPosition: '',
                goodsClassId: '',
                page: 1,
                limit: 10,
                desc: true
            },
            bannerId: '',
            formListQuery: Object.assign({}, defaultFormListQuery),
            //组件标签是否显示
            arg: {
                name: true,
                brand: true,
                jumpType: true,
                type: true,
                country: true,
                position: true,
                state: true,
                enable: true
            },
            placeholder: {
                date: {
                    start: '更新时间',
                    end: '更新时间'
                },
                state: '状态',
                name: '名称'
            },
            //编辑or新增
            isEdit: false,
            pageData: {},
            ossSignature: {
                host: ''
            },
            dialogFormVisible: false,
            fullscreenLoading: false,
            dictionary: Object.assign({}, defaultDictionary),
            goodsHomeRecomandRequest: {
                beginTime: '',
                brandCode: '',
                countryCode: '',
                enabled: false,
                endTime: '',
                goodsId: '',
                imageUrl: '',
                modularCode: '',
                platformCode: '',
                sort: '',
                goodsName: '',
                goodsBarcode: ''
            }, //查询项
            searchData: {
                title: '',
                enabled: null,
                brand: null,
                country: null,
                platform: null,
                classSetId: null,
                page: 1,
                limit: 10
            },
            goodsBarcode: '',
            ossHost: process.env.VUE_APP_OSS_Head,
            brandDicts: []
        };
    },
    mounted() {
        this.handleSearchList();
        this.getDictItems();
    },
    created(){
        this.getDictItems();
    },
    filters: {
        formatDate(time, type = 'yyyy-MM-dd hh:mm:ss') {
            if (time == null || time === '') {
                return 'N/A';
            }
            let date = new Date(time);
            return formatDate(date, type);
        },
        enumeration(val) {
            return enumeration(val);
        }
    },
    methods: {
        //搜索列表
        handleSearchList(listQuery) {
            let type = 'yyyy-MM-dd hh:mm:ss';
            let beginTime = moment().unix() * 1000;
            let endTime = moment().unix() * 1000;
            let nowTime = moment(this.formListQuery.time[0]).unix() * 1000;
            let nowTime2 = new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 - 1;
            if (listQuery) {
                this.listQuery.page = 1;
                console.log('listQuery', listQuery);
                let bannerJumpType = '';
                let status = '';
                bannerJumpType = listQuery.jumpType;
                if (listQuery.enable === true) {
                    status = 'true';
                } else if (listQuery.enable === false) {
                    status = 'false';
                }
                this.listQuery = {
                    beginTime: '',
                    endTime: '',
                    brandCode: listQuery.brand,
                    bannerJumpType: bannerJumpType,
                    status: status,
                    countryCodes: listQuery.country,
                    bannerType: listQuery.type,
                    bannerPosition: listQuery.position,
                    title: listQuery.title,
                    page: this.listQuery.page,
                    limit: this.listQuery.limit,
                    desc: true
                };
                if (listQuery.state == '1') {
                    this.listQuery.beginTime = moment(beginTime).unix() * 1000;
                    this.listQuery.endTime = moment(endTime).unix() * 1000;
                } else if (listQuery.state == '2') {
                    this.listQuery.beginTime = moment(endTime).unix() * 1000;
                    this.listQuery.endTime = null;
                } else if (listQuery.state == '3') {
                    this.listQuery.beginTime = null;
                    this.listQuery.endTime = moment(beginTime).unix() * 1000;
                }
            }
            this.$store.dispatch('getBanner', this.listQuery).then((res) => {
                this.pageData = res;
                this.pageData.list.forEach((item, index) => {
                    //状态
                    console.log('nowTime2', nowTime);
                    this.pageData.list[index].imgUrl1 = item.bannerFiles.find((item) => {
                        if (item.imgUrl) {
                            return item.imgUrl;
                        }
                    });
                    this.pageData.list[index].videoUrl1 = item.bannerFiles.find((item) => {
                        if (item.videoUrl) {
                            return item.videoUrl;
                        }
                    });
                    this.pageData.list[index].jumpUrl1 = item.bannerFiles.find((item) => {
                        if (item.jumpUrl) {
                            return item.jumpUrl;
                        }
                    });
                    if (item.beginTime > nowTime) {
                        this.pageData.list[index].status1 = 'NotStarted';
                        this.pageData.list[index].statusColor = 'red';
                    } else if (item.endTime < nowTime) {
                        this.pageData.list[index].status1 = 'Complete';
                        this.pageData.list[index].statusColor = 'black';
                    } else if (item.endTime >= nowTime && item.beginTime <= nowTime) {
                        this.pageData.list[index].status1 = 'UnderWay';
                        this.pageData.list[index].statusColor = 'green';
                    }
                    //位置
                    if (item.bannerPosition === 'HOMEPAGE') {
                        this.pageData.list[index].position = '首页banner';
                    } else if (item.bannerPosition === 'HOMERECOMMENDED') {
                        this.pageData.list[index].position = '首页推荐位置';
                    }
                    //bannerType
                    if (item.bannerType === 'Picture') {
                        this.pageData.list[index].bannerType1 = '图片';
                    } else if (item.bannerType === 'Video') {
                        this.pageData.list[index].bannerType1 = '视频';
                    }
                });

                console.log('this.listQuery', this.pageData.list);
            });
        },
        handleResetSearch() {
            this.listQuery = Object.assign({}, this.defaultListQuery);
            this.handleSearchList();
        },
        //自定义label
        renderSortHeader() {
            return (
                <div>
                    <span class="sort-span" on-click={() => this.handleSort()}>
                        排序 <span class="el-icon-sort"></span>
                    </span>
                </div>
            );
        },
        //排序
        handleSort() {
            this.listQuery.desc = !this.listQuery.desc;
            setTimeout(() => {
                this.handleSearchList();
            });
        },
        //打开新增
        add() {
            this.isEdit = false;
            this.formListQuery.bannerJumpType = 'Link';
            this.formListQuery.bannerPosition = 'HomePage';
            console.log(this.formListQuery);
            this.dialogFormVisible = true;
        },
        //打开编辑
        edit(row) {
            console.log('erow', row);
            this.isEdit = true;
            this.bannerId = row.bannerId;
            this.formListQuery = JSON.parse(JSON.stringify(row));
            this.formListQuery.time = [];
            if (this.formListQuery.beginTime && this.formListQuery.endTime) {
                let type = 'yyyy-MM-dd hh:mm:ss';
                let beginTime = this.$options.filters['formatDate'](this.formListQuery.beginTime, type);
                let endTime = this.$options.filters['formatDate'](this.formListQuery.endTime, type);
                this.formListQuery.time.push(beginTime);
                this.formListQuery.time.push(endTime);
                console.log('this.formListQuery.time', this.formListQuery.time);
            }
            let arr = [];
            if (row.bannerCountries && row.bannerCountries.length > 0) {
                row.bannerCountries.forEach((item) => {
                    arr.push(item.countryCode);
                });
            }
            this.$set(this.formListQuery, 'bannerCountriesList', arr);
            console.log('set', this.formListQuery.bannerCountriesList);
            this.dialogFormVisible = true;
        },
        //删除
        handleDelete(row) {
            this.$confirm('是否要删除?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                console.log('row', row);
                this.$store
                    .dispatch('deleteBanner', {
                        id: row.bannerId
                    })
                    .then((res) => {
                        if (res != null) {
                            let totalPage = Math.ceil((this.pageData.total - 1) / this.listQuery.limit);
                            let currentPage = this.listQuery.page > totalPage ? totalPage : this.listQuery.page;
                            this.listQuery.page = currentPage < 1 ? 1 : currentPage;
                            this.handleSearchList();
                            this.$message({
                                type: 'success',
                                message: '删除成功!'
                            });
                        }
                    });
            });
        },
        //关闭窗口
        closeDialog() {
            this.dialogFormVisible = false;
            this.formListQuery = Object.assign({}, defaultFormListQuery);
            this.dictionary = Object.assign({}, defaultDictionary);
        },
        putSortBanner(row) {
            const str = '^(\\d+)\\.(\\d{0}).*$';
            const reg = new RegExp(str);
            row.sort = row.sort.replace(/[^\d.]/g, '');
            row.sort = row.sort.replace(reg, '$1');
            this.$store
                .dispatch('putSortBanner', {
                    id: row.bannerId,
                    sort: row.sort ? row.sort : 0
                })
                .then((res) => {
                    if (res) {
                        this.handleSearchList();
                    }
                });
        },
        handleCurrentChange(val) {
            this.listQuery.page = val;
            this.handleSearchList();
        },
        deletegoodsHomeRecomand(row) {
            this.$confirm('确认删除?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            })
                .then(() => {
                    this.$store
                        .dispatch('deleteGoodsHomeRecomand', {
                            recomandId: row.recomandId
                        })
                        .then((res) => {
                            this.handleSearchList();
                        });
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
        },
        getDictItems() {
            var _this = this;
            this.$store.dispatch('getDicItems', { tag: 'country_ot' }).then((res) => {
                if (res != null){
                    defaultDictionary.country = res;
                    _this.dictionary.country = res;
                }
            });
            this.$store.dispatch('getDicItems', { tag: 'banner_position' }).then((res) => {
                if (res != null){
                    defaultDictionary.bannerPosition = res;
                    _this.dictionary.bannerPosition = res;
                }
            });
            this.$store.dispatch('getDicItems', { tag: 'brand' }).then((res) => {
                if (res != null){
                    defaultDictionary.brandCode = res;
                    _this.dictionary.brandCode = res;
                }
            });
            this.$store.dispatch('getDicItems', { tag: 'lang' }).then((res) => {
                if (res != null){
                    defaultDictionary.lang = res;
                    _this.dictionary.lang = res;
                }
            });
            
        },
    },
    updated() {}
};
</script>
<style scoped>
.sort-span {
    color: #000000;
}
</style>
