<template>
    <div class="app-container">
        <div class="filter-container">
            <el-input
                v-model="query.email"
                placeholder="邮箱地址"
                style="width: 180px"
                class="filter-item"
                @keyup.enter.native="handleQuery"
                oninput="value=value.replace(/[%]/g,'')"
                clearable
            />
            <dataSelect
                style="width: 180px; margin-left: 10px"
                class="filter-item"
                v-model="query.brandCode"
                clearable
                :codeType="'brand'"
                placeholder="品牌"
            ></dataSelect>
            <el-select
                v-model="query.countryCode"
                :multiple="true"
                placeholder="展示国家"
                style="width: 180px; margin-left: 10px"
                class="filter-item"
            >
                <el-option v-for="cc in countrysDicts" :key="cc.code" :label="cc.name" :value="cc.code"></el-option>
            </el-select>
            <el-select v-model="query.status" placeholder="订阅状态" clearable style="width: 180px; margin-left: 10px" class="filter-item">
                <el-option label="订阅中" value="1"></el-option>
                <el-option label="已取消" value="0"></el-option>
            </el-select>
            <el-button class="filter-item" icon="el-icon-search" type="primary" @click="handleQuery" style="margin-left: 10px">
                查询
            </el-button>
            <el-button class="filter-item" icon="el-icon-search" type="primary" @click="cleanQuery" style="margin-left: 10px" size="small">
                重置
            </el-button>
            <el-button class="filter-item" icon="el-icon-search" type="primary" @click="handleExport" style="margin-left: 10px" size="small">
                导出
            </el-button>
        </div>

        <el-table v-loading="listLoading" :data="list.list" border stripe style="width: 100%; margin-top: 20px">
            <el-table-column label="ID" prop="subId" align="center" show-overflow-tooltip> </el-table-column>
            <el-table-column label="品牌" prop="brandCode" align="center" show-overflow-tooltip> </el-table-column>
            <el-table-column label="邮箱" prop="email" align="center" show-overflow-tooltip> </el-table-column>
            <el-table-column prop="countryCode" label="国家" show-overflow-tooltip align="center"> 
                <template slot-scope="scope">
                    <dataText v-model="scope.row.countryCode" :codeType="'country_ot'"></dataText>
                </template>
            </el-table-column>

            <el-table-column label="订阅来源" prop="subFrom" align="center" show-overflow-tooltip>
                <template slot-scope="{ row }">
                    <span v-if="row.subFrom == 'Email'" >{{ '输入邮箱订阅' }}</span>
                </template>
            </el-table-column>
            <el-table-column label="状态" prop="status" align="center" show-overflow-tooltip>
                <template slot-scope="{ row }">
                    <span v-if="row.status == false" style="color: red">{{ '已取消' }}</span>
                    <span v-if="row.status == true" style="color: green">{{ '订阅中' }}</span>
                </template>
            </el-table-column>
            <el-table-column label="订阅时间" prop="subTime" align="center" show-overflow-tooltip :formatter="formatDate">
            </el-table-column>
            <el-table-column label="更新时间" prop="updateTime" align="center" show-overflow-tooltip :formatter="formatDate">
            </el-table-column>
            <el-table-column prop="updateByName" label="操作人" align="center"></el-table-column>
            <el-table-column label="操作" align="center" width="230">
                <template slot-scope="{ row }">
                    <el-button v-if="row.status" type="primary" size="mini" @click="handleUpdate(row)" class="table-inner-button"
                        >取消订阅</el-button
                    >
                    <el-button v-else type="danger" size="mini" @click="handleUpdate(row)" class="table-inner-button">重新订阅</el-button>
                </template>
            </el-table-column>
        </el-table>
        <div class="block">
            <el-pagination
                style="margin-top: 10px"
                background
                @current-change="handleCurrentChange"
                :current-page="query.page"
                :page-size="query.size"
                layout="total, prev, pager, next, jumper"
                :total="list.total"
            ></el-pagination>
        </div>
    </div>
</template>

<script>
import dataSelect from '@/components/common/DataSelect.vue';
import dataText from '@/components/common/DataText.vue';
import Pagination from '@/components/common/Pagination/';
import moment from 'moment';
import translateEditor from '@/components/page/editor/translateEditor2.vue';
import ossUploader from '@/components/page/OssEleUploder.vue';

export default {
    name: 'subscribe',
    components: {
        dataSelect,
        dataText,
        Pagination,
        moment,
        translateEditor,
        ossUploader
    },
    data() {
        var checkDateTime = (rule, value, callback) => {
            if (this.valuePicker.length > 0) {
                callback();
            } else {
                callback(new Error('请选择时间范围'));
            }
        };
        return {
            list: [],
            total: 0,
            listLoading: true,
            countrysDicts: [],
            query: {
                page: 1,
                limit: 10,
                email: '',
                brandCode:null,
                countryCode: [],
                status: null
            },
            ossHost: process.env.VUE_APP_OSS_Head,
            ossSignature: { host: '' }
        };
    },
    created() {
        this.doQueryList({ page: 1 });
        this.getDictItems();
    },
    mounted() {
        this.getDictItems();
    },
    methods: {
        /**
         * 分页
         */
        handleCurrentChange(val) {
            this.query.page = val;
            console.log(val);
            this.doQueryList({ page: val });
        },
        /**
         * 时间戳转码
         */
        formatDate(row, column) {
            // 获取单元格数据
            let data = row[column.property];
            if (!data) {
                return '';
            } else {
                return moment(data).format('YYYY-MM-DD HH:mm:ss');
            }
        },
        /**
         * 触发搜索操作
         */
        handleQuery() {
            this.doQueryList({ page: 1 });
        },
        /**
         * 执行列表查询
         */
        doQueryList({ page, limit }) {
            if (page) {
                this.query.page = page;
            }
            if (limit) {
                this.query.limit = limit;
            }
            this.listLoading = true;
            this.$store
                .dispatch('getSubscribePage', this.query)
                .then((res) => {
                    this.list = res;
                })
                .finally(() => {
                    this.listLoading = false;
                });
        },
        /**
         * 打开编辑表单
         */
        handleUpdate(row) {
            this.doUpdate(row);
        },
        doUpdate(row) {
            console.log(row.subId);
            this.$store.dispatch('subscribeEdit', { id: row.subId }).then((res) => {
                this.doQueryList({ page: 1 });
                this.$message({
                    message: '编辑成功',
                    type: 'success'
                });
            });
        },

        /**
         * 导出excel
         */
        handleExport() {
            this.query.fileName = '用户订阅数据.xlsx'
            console.log(this.query);
            this.$confirm('是否确认导出文件, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            })
                .then(() => {
                    this.$store.dispatch('exportSubExcel',this.query ).then((res) => {
                        this.$message({
                            type: 'success',
                            message: '导出成功'
                        });
                    });
                })
                .catch(() => {});
        },

        getDictItems() {
            var _this = this;
            this.$store.dispatch('getDicItems', { tag: 'countrys' }).then((res) => {
                if (res != null) _this.countrysDicts = res;
            });
        },
        cleanQuery() {
            this.query.email = '';
            this.query.countryCode = [];
            this.query.status = null;
            this.query.brandCode = null;
            this.doQueryList({ page: 1 });
        }
    }
};
</script>
<style scoped>
.filter-container {
    padding: auto;
    margin-left: 20px;
    padding-top: 20px;
}
</style>
